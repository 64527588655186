//#region  imports
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
//#endregion imports

//#region  internal imports
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { AuthenticationPath, ControlPanelPath } from 'app/core/common/route-constant';
//#endregion internal imports

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuardService  {
  isLoggedin = false;
  redirectUrl: string;

  constructor(private authService: AuthenticationService,
    private appDataService: AppDataService,
    private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      this.appDataService.navigateUrl  = route.url == null || `${AuthenticationPath.Login}` ? `${ControlPanelPath.ControlPanel}` :  state.url;
      return this.checkLogin();
  }

  
  checkLogin(): boolean{
        if(this.authService.getToken() && this.appDataService.connectedUser.control_panel_active){
            return true;
        }
        else {
            this.router.navigateByUrl(`${AuthenticationPath.Login}`);
        }

        return false;
    }
}
