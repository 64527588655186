//#region Imports
import {
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { Constant } from '../../common/constant';
import { Dropdown } from '../../model/common/dropdown.model';
import { LoggedUser } from '../../model/users/logged-user.modal';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouteHistoryService } from '../../services/route-history.service';
import { version } from 'environments/version';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { AdministatorPath, AuthenticationPath } from 'app/core/common/route-constant';

//#endregion Imports

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements AfterViewInit {
  sideBarStatus: boolean = true;
  @ViewChild('pagemain') elementView: ElementRef;
  @ViewChild('viewportChecker', { static: true })
  viewportCheckerRef: ElementRef;
  viewHeight: number;
  loggedInUser: LoggedUser = null;
  initals: string = null;
  appClass: string;
  rightBar = false;
  rightBarRole = Constant.RightBar;
  menuRoleName: string;
  currentRole: string;
  roleList: Dropdown[];
  loading: boolean = false;
  showKYC: boolean = false;
  kycHelpText: string = '';
  notification: any = [];
  notificationCount: number = 0;
  no_of_day: number = 0;
  plan_expiring: boolean = false;
  displayPlan: boolean = false;
  toggleLogo: boolean = false;
  version_update: boolean = false;
  expired: boolean = false;
  renewal_mode: boolean = false;
  displayReminder: boolean = false;
  showKYCNotice: boolean = false;
  KYCNoticeType: number = 0;
  reminder_type: string = '';
  set_pin: boolean = false;
  classification_list:any[]=[];
  show_classification:boolean = false;
  leftTooltipItems: MenuItem[] = [
    {
      tooltipOptions: {
        tooltipLabel: 'Video Tutorials',
        tooltipPosition: 'left',
      },
      icon: 'icon-fab-video',
      command: () => {
        this.router.navigate([`${AdministatorPath.Administrator +'/'+ AdministatorPath.VideoTutorial}`]);
      },
    },
    // {
    //   tooltipOptions: {
    //     tooltipLabel: 'Take a guided tour',
    //     tooltipPosition: 'left',
    //   },
    //   icon: 'icon-fab-tour',
    //   command: () => {
    //     this.router.navigate(['/administrator/faq']);
    //   },
    // },
    {
      tooltipOptions: {
        tooltipLabel: 'FAQs',
        tooltipPosition: 'left',
      },
      icon: 'icon-fab-faq',
      command: () => {
        this.router.navigate([`${AdministatorPath.Administrator +'/'+ AdministatorPath.FAQ}`]);
      },
    },
    {
      icon: 'icon-fab-help',
      tooltipOptions: {
        tooltipLabel: 'Help',
        tooltipPosition: 'top',
      },
      command: () => {
        this.sendHelptext();
      },
    },
  ];
  currentChangedRole: string = '';
  //#region Constructor
  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private appData: AppDataService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private routeHistoryService: RouteHistoryService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          event.url.includes('/administrator/manage-subscription') ||
          event.url.includes('/administrator/upgrade-subscription')
        ) {
          this.renewal_mode = true;
          this.displayReminder = false;
        } else {
          this.renewal_mode = false;
        }
      });
  }
  //#endregion Constructor

  ngOnInit() {
    this.verify_version();
    this.setRolesLocal();
    this.loggedInUser = this.appData.connectedUser;
    this.currentRole = this.appData.currentUserRole;
    this.appData.role$.subscribe((x) => {
      this.currentRole = x.role_name;
    });
    //#region current plan calculation
    let targetDate = new Date(this.loggedInUser.organization.end_date);
    let currentDate = new Date();

    // Calculate the time difference in milliseconds
    let timeDiff = targetDate.getTime() - currentDate.getTime();

    // Convert the time difference to days
    this.no_of_day = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    if (this.no_of_day <= 25) {
      this.plan_expiring = true;
    }
    if (this.no_of_day < 0) {
      this.expired = true;
      this.appData.plan_expired = true;
    }

    //#endregion
    this.cdRef.detectChanges();
    this.listenToLoading();
    this.loadNotification();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routeHistoryService.setLastRoute(event.urlAfterRedirects);
      }
    });
    this.commonService.purchasePlanEmitter.subscribe((purchase) => {
      if (purchase) {
        this.toggelPlan();
      }
    });
    this.calculateReminder();
  }

  ngAfterViewInit() {
    this.loggedInUser = this.appData.connectedUser;
    this.initals = this.loggedInUser.first_name.charAt(0).toUpperCase();
    this.handleKyc();
    this.cdRef.detectChanges();
    this.checkForClassificationExpense();
    this.checkViewportHeight();
    window.addEventListener('resize', this.checkViewportHeight.bind(this));
  }

  private getViewportHeight(): number {
    return Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
  }

  private checkViewportHeight(): void {
    this.viewHeight = this.getViewportHeight();
    this.cdRef.detectChanges();
  }

  sideBarEventHandler(event) {
    if (event) {
      this.appClass =
        'vertical-layout vertical-menu-modern navbar-sticky footer-static pace-done menu-collapsed';
      this.sideBarStatus = false;
    } else {
      this.appClass =
        'vertical-layout vertical-menu-modern content-left-sidebar footer-static pace-done menu-expanded navbar-sticky';
    }
    this.cdRef.detectChanges();
  }

  onLogout() {
    localStorage.clear();
    this.router.navigate([`${AuthenticationPath.Login}`]);
  }

  rightBarHandler(type) {
    this.rightBar = true;
    if (this.rightBarRole.Notification == type) {
      this.menuRoleName = this.rightBarRole.Notification;
    } else if (this.rightBarRole.Profile == type) {
      this.menuRoleName = this.rightBarRole.Profile;
    } else if (this.rightBarRole.Setting == type) {
      this.menuRoleName = this.rightBarRole.Setting;
    }
  }

  setRolesLocal() {
    this.commonService.getRoleForUser().subscribe((x) => {
      this.roleList = x as unknown as Dropdown[];
      let roleId = this.roleList.findIndex(
        (x) => x.name.toLocaleLowerCase() == Constant.RoleName.Admin
      );
      localStorage.setItem(
        Constant.RoleName.Admin.toLocaleLowerCase(),
        this.roleList[roleId].id.toString()
      );

      roleId = this.roleList.findIndex(
        (x) => x.name.toLocaleLowerCase() == Constant.RoleName.Finance
      );
      localStorage.setItem(
        Constant.RoleName.Finance.toLocaleLowerCase(),
        this.roleList[roleId].id.toString()
      );

      roleId = this.roleList.findIndex(
        (x) => x.name.toLocaleLowerCase() == Constant.RoleName.Manager
      );
      localStorage.setItem(
        Constant.RoleName.Manager.toLocaleLowerCase(),
        this.roleList[roleId].id.toString()
      );

      roleId = this.roleList.findIndex(
        (x) => x.name.toLocaleLowerCase() == Constant.RoleName.User
      );
      localStorage.setItem(
        Constant.RoleName.User.toLocaleLowerCase(),
        this.roleList[roleId].id.toString()
      );
    });
  }

  handleKyc() {
    let status = this.appData.connectedUser.kyc_status;
    if (status == Constant.KYCStatus.Initiated || status == Constant.KYCStatus.Pending) {
      this.showKYC = true;
    } else if (status == Constant.KYCStatus.Completed) {
      this.showKYC = false;
    }
  }

  handleSideBarEvent(event) {
    this.rightBar = !this.rightBar;
  }

  handleUserSetting($event) {
    this.rightBar = true;
    this.menuRoleName = this.rightBarRole.Setting;
  }

  listenToLoading(): void {
    this.commonService.data$.subscribe((loading) => {
      if (loading == 'set-pin') {
        let user = this.appData.connectedUser;
        user.pin_status = false;
        this.appData.connectedUser = user;
        this.set_pin = true;
      }
      if (loading == 'show') {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

  loadNotification() {
    this.commonService.getUserNotification().subscribe((x) => {
      if (x && x.notifications) {
        this.notification = x.notifications;
        this.notificationCount = x.notifications.length;
      } else {
        this.notification = [];
      }
    });
  }

  handleMarkNotification() {
    this.notificationCount = 0;
  }

  toggelPlan() {
    if (this.loggedInUser.organization.subscription_type_id == 4) {
      this.router.navigate([`${AdministatorPath.Administrator +'/'+ AdministatorPath.ManageSubscription}`]);
    } else {
      this.displayPlan = !this.displayPlan;
    }
  }

  toggleSideMenu() {
    this.toggleLogo = !this.toggleLogo;
    this.sideBarEventHandler(false);
    this.sideBarStatus = true;
  }

  verify_version() {
    const queryParams = {
      platform: 'WEB',
      version: version,
    };
    let str = `/compare_version/`;
    this.commonService.verify_version(str, queryParams).subscribe((x) => {
      this.version_update = !x.status;
    });
  }

  reloadApp() {
    window.location.reload();
  }
  handleSubcriptionEvent(event) {
    this.displayPlan = !this.displayPlan;
  }

  calculateReminder() {
    if (
      this.currentRole.toLocaleLowerCase() ===
        Constant.RoleName.Admin.toLocaleLowerCase() ||
      this.currentRole.toLocaleLowerCase() ==
        Constant.RoleName.Finance.toLocaleLowerCase()
    ) {
      const isPaid = this.loggedInUser.organization.subscription_type_id;
      const reminders = {
        5: isPaid === 1 ? '5' : '5P',
        15: isPaid === 1 ? '15' : '15P',
        25: isPaid === 1 ? '25' : null,
      };
      if (
        reminders.hasOwnProperty(this.no_of_day) &&
        this.renewal_mode == false
      ) {
        this.reminder_type = reminders[this.no_of_day];
        if (this.reminder_type !== null) {
          this.displayReminder = true;
        }
      }
    }
  }

  checkUserKycStatus() {
    this.commonService.getCardDetails().subscribe((data) => {
      this.KYCNoticeType = data.status;
      if (data.status == 1) {
        this.loggedInUser.kyc_status = 'C';
        this.appData.connectedUser = this.loggedInUser;
        this.showKYC = !this.showKYC;
        this.showKYCNotice = true;
        setTimeout(() => {
          this.showKYCNotice = false;
        }, 3000);
      } else {
        this.showKYCNotice = true;
        setTimeout(() => {
          this.showKYCNotice = false;
        }, 10000);
      }
    });
  }

  handleUpgradeType(event) {
    this.displayReminder = false;

    if (event == 'FREE') {
      this.displayPlan = !this.displayPlan;
    } else {
      this.router.navigate([`${AdministatorPath.Administrator +'/'+ AdministatorPath.ManageSubscription}`]);
    }
  }

  closeReminder() {
    this.displayReminder = false;
  }

  handlePinStatus(event) {
    this.set_pin = !this.set_pin;
  }

  toggleMaskData(value) {}

  sendHelptext() {
    let msg =
      'Hi, I need assistance regarding BizPay. My user name is ' +
      this.appData.connectedUser.first_name +
      ' ' +
      this.appData.connectedUser.last_name +
      ', and Email id is *' +
      this.appData.connectedUser.email +
      '*';
    let whatsappUrl = 'https://wa.me/+917021882996?text=' + msg;
    window.open(whatsappUrl, '_blank');
  }

  checkForClassificationExpense(){
    this.commonService.checkForClassificationExpense().subscribe(resp=>{
      if(resp.length!=0){
        this.classification_list=resp
        this.show_classification =true;
      }else{
        this.show_classification=false;
      }
    })
  }

  handleClassifyModal(event){
    this.show_classification = event;
  }

  handleRoleChange(changedRole: string) {
    this.currentChangedRole = changedRole;
  }
}
