import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { LoggedUser } from '../../model/users/_module';
import { UserService } from 'app/modules/user/service/user.service';
import { AuthenticationPath } from 'app/core/common/route-constant';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  connectedUser:LoggedUser;
  currentRole: string;
  initals:string;
  employee_id: number;
  @Input() day_remaining =0;
  @Output() closeSideBarEvent = new EventEmitter<boolean>();
  @Output() subscriptionModeEvent = new EventEmitter<boolean>();
  @Input() changedRole = '';
  primaryAccount: boolean = false;
  
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private appData: AppDataService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.connectedUser=this.appData.connectedUser
    this.currentRole=this.appData.currentUserRole
    this.initals = this.connectedUser.first_name.charAt(0).toUpperCase() + this.connectedUser.last_name.charAt(0).toUpperCase();
    this.getEmployeeId()
    this.changedRole = this.currentRole.toLowerCase();
    this.primaryAccount = this.connectedUser.primary_account
  }

  logout() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    localStorage.clear();

    this.authService.logout().subscribe(
      (x)=>{
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
        this.router.navigate([`${AuthenticationPath.Login}`]);

      },error=>{
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
        this.router.navigate([`${AuthenticationPath.Login}`]);
      }
    );
  }

  getEmployeeId(){
    this.userService.getUserData(this.connectedUser.user_id).subscribe((x)=>{this.employee_id = x.employee_id})
  }

  handleRedirection() {
    this.closeSideBarEvent.emit(true);
    if(this.day_remaining <25 &&  this.appData.connectedUser.organization.subscription_type_id < 3){
      this.subscriptionModeEvent.emit(true);
    }else{
      this.router.navigateByUrl('/administrator/subscription');
    }
  }

  handleChangePassword() {
    this.router.navigateByUrl('/user/change-password');
    this.closeSideBarEvent.emit(true);
  }

  handleViewDispute(){
    this.router.navigateByUrl('/user/view-disputes');
    this.closeSideBarEvent.emit(true);
  }
}
