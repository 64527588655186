import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'app/core/services/common.service';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { NgOtpInputComponent } from 'ng-otp-input';
import { DashboardPath } from 'app/core/common/route-constant';

@Component({
  selector: 'app-manage-pin',
  templateUrl: './manage-pin.component.html',
  styleUrls: ['./manage-pin.component.scss'],
})
export class ManagePinComponent {
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;
  mode: string = 'set';
  new_otp = null;
  confirm_otp = null;
  mismatch:boolean = false;
  @Output() pinStatus = new EventEmitter<string>();
  @ViewChild('otpInput') otpInputField: any;
  @ViewChild('otpInput1') otpInputField1: any;
  setButtonEnabled: boolean = false;
  cnfButtonEnabled: boolean = false;

  constructor(
    private appDataService: AppDataService,
    private cpService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    // if (this.appDataService.connectedUser.pin_status) {
    //   this.mode = 'confirm';
    // } else {
      this.mode = 'set';
    // }
  }

  onOtpChange(event) {
    if (event.length == 4) {
      if (this.mode == 'set') {
        this.setButtonEnabled = true;
        this.new_otp = event;
      } else {
        this.cnfButtonEnabled = true;
        this.confirm_otp = event;
      }
    }else{
      this.setButtonEnabled = false;
      this.cnfButtonEnabled = false;
    }
    if (this.mismatch == true && event.length != 0){
      if (event.length == 1){
        this.mismatch = false;
      }
    }
  }

  setPin() {
    if (this.mode == 'set' && this.new_otp.length ==4) {
      this.mode = 're-confirm';
    }
  }

  confirmPin(){
    this.mismatch = false;
    if(this.new_otp == this.confirm_otp){
      let data = {
        'upi_pin':this.new_otp
      }
      this.cpService.setPinForUser(data).subscribe(d=>{
        if (d.status){
          this.pinStatus.emit("pin-set")
          let connectedUser = this.appDataService.connectedUser
          connectedUser.pin_status = true
          this.appDataService.connectedUser = connectedUser;
          this.mode = 'set';
          this.otpInputField1.setValue(null);

        }
      });
    }else{
      this.mismatch = true;
      this.otpInputField1.setValue(null);
    }
  }

  cancelPin(){
    if (this.appDataService.currentUserRole === 'Admin') {
      this.router.navigateByUrl(`${DashboardPath.Dashboard +'/'+ DashboardPath.AdminDashboard}`);
    } else if (this.appDataService.currentUserRole === 'Finance') {
      this.router.navigateByUrl(`${DashboardPath.Dashboard +'/'+ DashboardPath.FinanceDashboard}`);
    } else {
      this.router.navigateByUrl(`${DashboardPath.Dashboard +'/'+ DashboardPath.UserDashboard}`);
    }
    this.pinStatus.emit("pin-set")
  }
}
