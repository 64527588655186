import { NgModule } from '@angular/core';
import { PreloadAllModules } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '../core/services/shell.service';
import { AdminShell } from '../core/services/admin-shell.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('../modules/authentication/authentication.module').then((m) => {
        return m.AuthenticationModule;
      }),
  },
  {
    path: '',
    loadChildren: () =>
      import('../modules/authentication/authentication.module').then((m) => {
        return m.AuthenticationModule;
      }),
  },
  Shell.childRoutes([
    {
      path: 'user',
      loadChildren: () =>
        import('../modules/user/user.module').then((m) => {
          return m.UserModule;
        }),
    },
    {
      path: 'dashboard',
      loadChildren: () =>
        import('../modules/dashboard/dashboard.module').then((m) => {
          return m.DashboardModule;
        }),
    },
    {
      path: 'administrator',
      loadChildren: () =>
        import('../modules/administrator/administrator.module').then((m) => {
          return m.AdministratorModule;
        }),
    },
    {
      path: 'expense',
      loadChildren: () =>
        import('../modules/expense/expense.module').then((m) => {
          return m.ExpenseModule;
        }),
    },
    // {
    //   path: 'report',
    //   loadChildren: () =>
    //     import('../modules/expense/expense.module').then((m) => {
    //       return m.ExpenseModule;
    //     }),
    // },
    {
      path: 'approval',
      loadChildren: () =>
        import('../modules/expense/expense.module').then((m) => {
          return m.ExpenseModule;
        }),
    },
    {
      path: 'transcation',
      loadChildren: () =>
        import('../modules/transcation/transcation.module').then((m) => {
          return m.TranscationModule;
        }),
    },
    {
      path: 'settlement',
      loadChildren: () =>
        import('../modules/settlement/settlement.module').then((m) => {
          return m.SettlementModule;
        }),
    },
    {
      path: 'settings',
      loadChildren: () =>
        import('../modules/settings/settings.module').then((m) => {
          return m.SettingsModule;
        }),
    },
  ]),

  AdminShell.childRoutes([
    {
      path: 'admin-panel',
      loadChildren: () =>
        import('../modules/control-panel/control-panel.module').then((m) => {
          return m.ControlPanelModule;
        }),
    },
  ]),
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
