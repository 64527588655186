<!-- <ng-container  *ngIf="on_board_mode">
  <span class="text-danger">Disable On boarding mode to change roles</span>
  <br>
</ng-container> -->
<div>
  <strong>Change View</strong>
  <ul>
    <li class="mt-3" *ngIf="isprimaryaccount">
      <a href="javascript:void(0);" style="text-decoration: none;" (click)="toggleOnBoardStatus()">
        <div class="d-flex p-1 ps-0" (click)="setRole()">
          <img src="../../../../assets/img/onboarding-side-bar.svg" alt="" class="role-icon">
          <p class="role-name">
            {{enableOnBoard}} 
          </p>
          <div class="ml-auto">
            <p-inputSwitch [(ngModel)]="toggleFlag" />
            <!-- <img src="../../../../assets/img/role-checked.svg" alt="" class="icon-checked"> -->
          </div>
        </div>
      </a>
    </li>
    <li *ngFor="let role of loggedInUser.role" class="mt-3" [ngClass]="{'op-5': toggleFlag}">
      <div class="d-flex p-1 ps-0" (click)="setRole(role)" >
        <img src="../../../../assets/img/role-icon.svg" alt="" class="role-icon">
        <p class="role-name">
          {{role.role_name}}
        </p>
        <div class="ml-auto" *ngIf="role.role_name == currentRole && !toggleFlag">
          <img src="../../../../assets/img/role-checked.svg" alt="" class="icon-checked">
        </div>
      </div>
    </li>
  </ul>

</div>
<p-divider></p-divider>
<div class="text-center"><a href="javascript:void(0);" (click)="logout()" class="text-danger">Logout</a></div>
