<!-- <h4 style="font-weight: normal">Profile</h4> -->
<div class="text-center p-2">
  <span class="avatar p-2">
    <span class="avatar-content">{{initals}}</span> </span><br />
  <div class="my-2">
    <span class="user-name">{{connectedUser.first_name }} {{connectedUser.last_name}}</span>
  </div>
  <div class="my-2">
    <span class="emp-id">Employee ID #{{employee_id }}</span>
  </div>
  <div class="row d-flex gx-0 mt-3" [ngClass]="changedRole === 'admin' && primaryAccount ? 'justify-content-between' : 'justify-content-evenly'">
    <div class="col-3 text-center" *ngIf="changedRole === 'admin' && primaryAccount">
      <a (click)="handleRedirection()" href="javascript:void(0);" style="text-decoration: none;">
        <div class="card additional-options">
          <img src="../../../../assets/img/manage-view-subscription.svg" alt="" class="options-icon">
        </div>
        <p class="options-title mt-3 mx-auto ps-1">Manage/View Subscription</p>
      </a>
    </div>
    <div class="col-3 text-center">
      <a (click)="handleChangePassword()" href="javascript:void(0);" style="text-decoration: none;">
        <div class="card additional-options">
          <img src="../../../../assets/img/change-pwd.svg" alt="" class="options-icon">
        </div>
        <p class="options-title mt-3 mx-auto ps-1">Change Password</p>
      </a>
    </div>
    <div class="col-3 text-center">
      <a (click)="handleViewDispute()" href="javascript:void(0);" style="text-decoration: none;">
        <div class="card additional-options">
          <img src="../../../../assets/img/raise-tickets.svg" alt="" class="options-icon">
        </div>
        <p class="options-title mt-3 mx-auto ps-1">View Tickets</p>
      </a>
    </div>
  </div>
  <!-- <div><a href="javascript:void(0);" (click)="logout()">Logout</a></div> -->
</div>
<p-divider></p-divider>
