//#region imports
import { Injectable } from '@angular/core';
import { Constant } from 'app/core/common/constant';
import {
  LoggedUser,
  UserRole,
} from 'app/core/model/users/logged-user.modal';
import { OnBoard } from 'app/core/model/users/on-board.modal';
import { Subject } from 'rxjs';

//#endregion imports

//#region internal imports

//#endregion internal imports

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private _connectedUser: LoggedUser;
  private _navigateUrl: string;
  private _currentRole: string;
  private _plan_expired: boolean = false;
  private _applicationStage: OnBoard[];
  private _currencyOptions:any =null;

  private _role = new Subject<UserRole>();
  role$ = this._role.asObservable();

  constructor() {}

  changeRole(role: UserRole) {
    this._role.next(role);
  }

  //#region connected user
  public get connectedUser(): LoggedUser {
    if (this._connectedUser) {
      return this._connectedUser;
    } else {
      this._connectedUser = JSON.parse(
        localStorage.getItem(Constant.LocalStorageKey.User)
      );
      return this._connectedUser;
    }
  }

  public set connectedUser(value: LoggedUser) {
    this._connectedUser = value;
    localStorage.setItem(
      Constant.LocalStorageKey.User,
      JSON.stringify(this._connectedUser)
    );
  }

  //#endregion connected user

  //#region
  public get currentUserRole(): string {
    if (this._currentRole) {
      return this._currentRole;
    } else {
      this._currentRole = JSON.parse(
        localStorage.getItem(Constant.LocalStorageKey.CurrentRole)
      );
      return this._currentRole;
    }
  }

  public set currentUserRole(value: string) {
    this._currentRole = value;
    localStorage.setItem(
      Constant.LocalStorageKey.CurrentRole,
      JSON.stringify(this._currentRole)
    );
  }
  //#endregion

  //#region navigateurl
  public get navigateUrl() {
    if (this._navigateUrl) {
      return this._navigateUrl;
    } else {
      this._navigateUrl = JSON.parse(
        sessionStorage.getItem(Constant.LocalStorageKey.Url)
      );
      return this._navigateUrl;
    }
  }

  public set navigateUrl(value: string) {
    this._navigateUrl = value;
    sessionStorage.setItem(
      Constant.LocalStorageKey.Url,
      JSON.stringify(this._navigateUrl)
    );
  }
  //#endregion navigateurl

  //#region

  public get applicationStage() {
    if (this._applicationStage) {
      return this._applicationStage;
    } else {
      this._applicationStage = JSON.parse(
        sessionStorage.getItem(Constant.LocalStorageKey.ApplicationStage)
      );
      return this._applicationStage;
    }
  }

  public set applicationStage(value: OnBoard[]) {
    this._applicationStage = value;
    sessionStorage.setItem(
      Constant.LocalStorageKey.ApplicationStage,
      JSON.stringify(this._applicationStage)
    );
  }


  public get plan_expired() {
    if (this._plan_expired) {
      return this._plan_expired;
    } else {
      this._applicationStage = JSON.parse(
        sessionStorage.getItem('plan_expired')
      );
      return this._plan_expired;
    }
  }

  public set plan_expired(value: boolean) {
    this._plan_expired = value;
    sessionStorage.setItem(
      'plan_expired',
      JSON.stringify(this._plan_expired)
    );
  }


  //#endregion


  //#region

  public get currencyOption() {
    if (this._currencyOptions) {
      return this._currencyOptions;
    } else {
      this._currencyOptions = JSON.parse(
        localStorage.getItem(Constant.LocalStorageKey.CurrencyOptions)
      );
      return this._currencyOptions;
    }
  }

  public set currencyOption(value: any) {
    this._currencyOptions = value;
    localStorage.setItem(
      Constant.LocalStorageKey.CurrencyOptions,
      JSON.stringify(this._currencyOptions)
    );
  }

  //#endregion
}
