import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { Constant } from '../common/constant';
import {
  RouteConstantAdmin,
  RouteConstantFinance,
  RouteConstantManager,
  RouteConstantUsers,
} from '../common/route-constant';

@Injectable({
  providedIn: 'root',
})
export class RoleAuthGuard {
  isLoggedin = false;
  redirectUrl: string;
  constructor(private appDataService: AppDataService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const role = this.appDataService.currentUserRole.toLowerCase();
    const accessibleRoutes = this.getAccessibleRoutes(role);
    if (accessibleRoutes.some((route) => state.url.startsWith(route))) {
      return true;
    } else {
      this.router.navigateByUrl(this.getDashboardRoutes(role));
      return false;
    }
  }

  private getAccessibleRoutes(role: string): string[] {
    const roleToRoutesMap = {
      [Constant.RoleName.Admin]: [
        RouteConstantAdmin.route.AdminDashboard,
        RouteConstantAdmin.route.Approvals,
        RouteConstantAdmin.route.Expense,
        RouteConstantAdmin.route.ExpenseBucket,
        RouteConstantAdmin.route.Teams,
        RouteConstantAdmin.route.Approvals,
        RouteConstantAdmin.route.Expenses,
        RouteConstantAdmin.route.Settings,
        RouteConstantAdmin.route.OrganisationSettings,
        RouteConstantAdmin.route.OrganizationDetails,
        RouteConstantAdmin.route.Integrations,
        RouteConstantAdmin.route.UserSettings,
        RouteConstantAdmin.route.Users,
        RouteConstantAdmin.route.Wallets,
        RouteConstantAdmin.route.Onboarding,
        RouteConstantAdmin.route.ControlPanel,
        RouteConstantUsers.route.ReportDashboard,
        RouteConstantUsers.route.ExpenseForm,
      ],
      [Constant.RoleName.Manager]: [
        RouteConstantFinance.route.Approvals,
        RouteConstantFinance.route.Expenses,
        RouteConstantFinance.route.FinanceDashboard,
        RouteConstantFinance.route.MoneyRequests,
        RouteConstantFinance.route.Reports,
        RouteConstantFinance.route.Teams,
        RouteConstantFinance.route.Wallets,
      ],
      [Constant.RoleName.Finance]: [
        RouteConstantManager.route.Expense,
        RouteConstantManager.route.Teams,
        RouteConstantUsers.route.Approvals,
        RouteConstantFinance.route.FinanceDashboard,
      ],
      [Constant.RoleName.User]: [
        RouteConstantUsers.route.Approvals,
        RouteConstantUsers.route.MoneyRequests,
        RouteConstantUsers.route.MyWallets,
        RouteConstantUsers.route.Reports,
        RouteConstantUsers.route.UserDashboard,
        RouteConstantUsers.route.Expenses,
        RouteConstantUsers.route.ExpenseForm,
        RouteConstantUsers.route.ReportDashboard,
      ],
    };
    const routes = roleToRoutesMap[role.toLowerCase()] || [];
    return routes;
  }

  getDashboardRoutes(role: string) {
    if (role == Constant.RoleName.Admin) {
      return Constant.DashboardRoute.Admin;
    } else if (role == Constant.RoleName.Finance) {
      return Constant.DashboardRoute.Finance;
    } else if (role == Constant.RoleName.Manager) {
      return Constant.DashboardRoute.Manager;
    } else if (role == Constant.RoleName.User) {
      return Constant.DashboardRoute.User;
    } else {
      return '/';
    }
  }
}
