import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { ApiInterceptor } from '../core/interceptor/api.interceptor';
import { LoaderInterceptor } from '../core/interceptor/loader.interceptor';
import { AppInjector } from '../core/common/app.injector';
import { CoreModule } from '../core/core.module';
import { AuthGuardService } from '../core/guard/auth.guard';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimeNgModules } from './primeng.module';
import { HttpErrorInterceptor } from '../core/interceptor/error.interceptor';
import localeIn from '@angular/common/locales/en-IN';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CacheClearService } from '../core/services/cache-clear.service';
import { EncodeInterceptor } from 'app/core/interceptor/encode.interceptor';
import { RoleAuthGuard } from 'app/core/guard/role-auth.guard';

@NgModule({
  declarations: [
    AppComponent,

    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    FormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    ...PrimeNgModules,
    // NgxGoogleAnalyticsModule.forRoot('G-8G2JWXPM2Y'),
    // NgxGoogleAnalyticsRouterModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: EncodeInterceptor,
    multi: true,
  },
    AuthGuardService,
    // RoleAuthGuard,
    AuthenticationService,
    CacheClearService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
    registerLocaleData(localeIn);
  }
}
