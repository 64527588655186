import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { UserService } from 'app/modules/user/service/user.service';
import { LoggedUser, UserRole } from '../../model/users/logged-user.modal';
import { AdministatorPath, ControlPanelPath, UserPath } from 'app/core/common/route-constant';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  providers: [ConfirmationService],
})
export class ProfileMenuComponent implements OnInit {
  connectedUser: LoggedUser;
  isprimaryaccount: boolean = false;
  isadmin: boolean = false;
  enableOnBoard: string = '-';
  @Input() day_remaining =0;
  @Output() closeSideBarEvent = new EventEmitter<boolean>();
  @Output() subscriptionModeEvent = new EventEmitter<boolean>();

  constructor(
    private appDataService: AppDataService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.connectedUser = this.appDataService.connectedUser;
    this.isprimaryaccount = this.connectedUser.primary_account;
    this.isadmin = this.appDataService.currentUserRole.toLowerCase() == 'admin' ? true : false
    this.enableOnBoard =
      this.connectedUser.status == false
        ? 'Disable On Board'
        : 'Enable On Board';
  }

  toggleOnBoardStatus() {
    let status = {
      on_boarded_status: !this.connectedUser.status,
    };
    this.userService
      .toggleOnBoardingProcess(this.connectedUser.organization.id, status)
      .subscribe((x) => {
        this.connectedUser.status = !this.connectedUser.status;
        if(!this.connectedUser.status){
          localStorage.setItem("allow-on-boarding-process","true")
        }else{
          localStorage.removeItem("allow-on-boarding-process")
        }
        this.appDataService.connectedUser = this.connectedUser;
        this.router.navigateByUrl(`${ControlPanelPath.ControlPanel +'/'+ UserPath.Onboard}`);
        this.closeSideBarEvent.emit(true);
      });
   
  }

  handleChangePassword() {
    this.router.navigateByUrl(`${ControlPanelPath.User +'/'+ UserPath.ChangePassword}`);
    this.closeSideBarEvent.emit(true);
  }

  handleRedirection() {
    this.closeSideBarEvent.emit(true);
    if(this.day_remaining <25 &&  this.appDataService.connectedUser.organization.subscription_type_id < 3){
      this.subscriptionModeEvent.emit(true);
    }else{
      this.router.navigateByUrl(`${AdministatorPath.Administrator +'/'+ AdministatorPath.Subscription}`);
    }


  }

  handleViewDispute(){
    this.router.navigateByUrl(`${UserPath.ViewDisputes}`);
    this.closeSideBarEvent.emit(true);
  }
}
