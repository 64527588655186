//#region imports
import { Injectable } from '@angular/core';
import { Routes, Route } from '@angular/router';
//#endregion imports

//#region internal imports
import { AuthGuardService } from '../guard/auth.guard';
import { DefaultLayoutComponent } from '../layout/_module';
import { RoleAuthGuard } from '../guard/role-auth.guard';
//#endregion internal imports

@Injectable({
  providedIn: 'root'
})
export class Shell {

   /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: DefaultLayoutComponent,
      children: routes,
      canActivate: [AuthGuardService,RoleAuthGuard],
      // Reuse Component instance when navigating between child views
      data: { reuse: true }
    };
  }
}
