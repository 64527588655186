<!-- BEGIN: Main Menu-->

<div
  class="main-menu menu-fixed menu-light menu-accordion menu-shadow curve-box"
  data-scroll-to-active="true"
>
  <div class="d-flex text-center" *ngIf="sideBarStatus">
    <a class="icon-brand" href="javascript:void(0);">
      <img
        alt=""
        src="./../../../../assets/img/site-icon.svg"
        class="bg-white"
        width="48"
        height="48"
        (click)="toggleSideBar(true)"
      />
    </a>
  </div>

  <div class="navbar-header" *ngIf="!sideBarStatus">
    <ul class="nav navbar-nav" style="align-items: center">
      <li class="nav-item">
        <a class="navbar-brand" href="javascript:void(0);" >
          <img
          (click)="toggleSideBar(true)"
            alt=""
            src="./../../../../assets/img/white-logo.svg"
            height="54px"
          />
        </a>
      </li>
    </ul>
  </div>
  <div class="shadow-bottom"></div>
  <div class="main-menu-content my-3 biz-nav-scroll">
    <h5
      class="bg-primary text-white text-center py-3 pointer"
      *ngIf="!sideBarStatus"
      (click)="openUserSetting()"
    >
      {{ currentMode }}
    </h5>
    <ul
      class="navigation navigation-main"
      id="main-menu-navigation"
      data-menu="menu-navigation"
    >
      <li class="nav-item my-2" *ngFor="let data of sideMenuItem">
        <a *ngIf="data.active"
          class="d-flex align-items-center"
          [routerLink]="data.routerLink"
          [ngClass]="{ active: checkParentRoute(data.routerLink) }"
        >
          <i [class]="data.icon"></i>
          <span class="menu-title text-truncate">{{ data.name }}</span></a
        >
        <a *ngIf="!data.active"
        class="d-flex align-items-center disabled"
        [routerLink]="data.routerLink"
        disabled
      >
        <i [class]="data.icon"></i>
        <span class="menu-title text-truncate">{{ data.name }}</span></a
      >
      </li>
    </ul>

    <div class="field-checkbox d-flex" *ngIf="!hide_menu">
      <p-checkbox
        [(ngModel)]="sideBarStatus"
        [binary]="true"
        checkboxIcon="pi pi-microsoft"
        inputId="binary"
        (onChange)="toggleSideBar()"
      ></p-checkbox>
      <label for="binary" class="text-truncate ps-3" *ngIf="!sideBarStatus"
        >Toggle Sidebar</label
      >
    </div>
  </div>
</div>
<!-- END: Main Menu-->
